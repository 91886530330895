
const state = {
    active:'home',
    user:null,
    orderNo:'',
    language:0,
}

const mutations = {
    set_tabCur(state,data){
        state.active = data;
    },
    set_user(state,data){
        state.user = data;
    },
    set_order_no(state,data){
        state.orderNo = data;
    },
    set_language(state,data){
        state.language = data;
    },
}

const actions = {
    up_tabCur({commit},data){
        commit('set_tabCur',data)
    },
    up_user({commit},data){
        commit('set_user',data)
    },
    up_order_no({commit},data){
        commit('set_order_no',data)
    },
    up_language({commit},data){
        commit('set_language',data)
    },
}

const getters = {
    get_tabCur(state){
        return state.active
    },
    get_user(state){
        return state.user
    },
    get_order_no(state){
        return state.orderNo
    },
    get_language(state){
        return state.language
    },
    get_languageList(state){
        if (state.language === 0){
            return [
                {text: 'English', value: 'english'},
                {text: 'Chinese', value: 'chinese'},
            ]
        }else {
            return [
                {text: '英语', value: 'english'},
                {text: '中文', value: 'chinese'},
            ]
        }
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};