<template>
  <router-view/>
</template>

<script>
export default {
  created() {
    //在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', async () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state));
    });
  },
}
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@import "assets/iconfont/iconfont.css";
@import "assets/main.css";
</style>
