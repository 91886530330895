
export default {
    message:{
        home:'首页',
        package:'包裹',
        order:'订单',
        me:'我的',
        back:'返回',
        language:'语言',
        chinese:'简体中文',
        english:'英语',
        confirm:'确认',
        cancel:'取消'
    },
    login:{
        phone:'请输入手机',
        password: '请输入密码',
        loginTitle:'德莱米克',
        registerTitle: '德莱米克',
        name:'请输入客户编号',
        register:'注册',
        cancel:'取消',
        login:'登录',
        notAccount:'还没有账号？',
        createAccount:'注册账号'
    },
    home:{
        copyAll:'复制全部',
        copy:'复制',
        consignee:'收件人',
        address:'地址',
        mobile:'电话',
        addTrackingNumber:'添加快递单号',
        addTrackingLabel:'从您的供应商或网上商店添加跟踪号码',
        placeAnOrder:'下单',
        placeAnOrderLabel:'向我们订购海外空运/海运订单',
    },
    package: {
        notArrived:'未到仓',
        arrived:'已到仓',
        notPackage: '没有包裹',
        add:'添加',
        package:'包裹',
        addOrder:'添加订单',
        houseName:'交货仓库',
        houseNameLabel:'请选择仓库',
        courierNo:'快递单号',
        courierNoLabel:'请填写快递单号',
        transType:'运输方式',
        ship:'海运',
        plane:'空运'
    },
    order:{
        all:'全部',
        inHouse:'待装柜',
        transit:'运输中',
        arrived:'已到达',
        notOrder:'没有订单',
        orderNo:'订单号'
    },
    me:{
        myAssets:'我的资产',
        myService:'我的服务',
        points:'积分',
        balance:'余额',
        coupons:'优惠券',
        settings:'设置',
    },
    settings:{
        avatar:'头像',
        nickName:'呢称',
        changeName:'修改呢称',
        changeNameLabel:'请输入呢称',
        changePassword:'修改密码',
        logout:'退出',
        logoutAccount:'退出当前账号',
        title:'设置中心',
        oldPwd:'旧密码',
        oldPwdLabel:'输入旧密码',
        newPwd:'新密码',
        newPwdLabel:'输入新密码',
        confirmPwd:'确认密码',
        confirmPwdLabel:'再次输入新密码'
    },
    detail:{
        title:'订单详情',
        orderNo:'订单号',
        total:'合计',
        createTime: '创建时间',
        customCode:'客户编号',
        name:'收件人',
        mobile:'收件电话',
        from:'发出',
        to:'送往',
        container:'柜号',
        etd:'到港时间',
        eta:'开船时间',
        specification:'货物',
        goods:'品名',
        dimension:'尺寸',
        weight:'重量',
        ctn:'件数',
        package:'快递',
        logisticsStatus:'订单轨迹',
        remark:'备注'
    }
}