export default {
    message:{
        home:'Home',
        package:'Package',
        order:'Order',
        me:'Me',
        back:'back',
        language:'language',
        chinese:'Chinese',
        english:'English',
        confirm:'confirm',
        cancel:'cancel',
        save:'save',
    },
    login:{
        phone:'Phone Number',
        password: 'Password',
        loginTitle:'Dynamic Supply Chain',
        registerTitle: 'Register With DSC',
        name:'Your Customer Code',
        register:'Registration',
        cancel:'Cancel',
        login:'Request DscCargo',
        notAccount:'Not registered yet?',
        createAccount:'Create an account'
    },
    home:{
        copyAll:'Copy Full Shipping Address',
        copy:'copy',
        consignee:'Consignee',
        address:'Address',
        mobile:'Mobile',
        addTrackingNumber:'Add Tracking Number',
        addTrackingLabel:'Add tracking number from your suppliers or online stores',
        placeAnOrder:'Place An Order',
        placeAnOrderLabel:'Place an oversea air/sea shipping order with us',
    },
    package: {
        notArrived:'Not Arrived',
        arrived:'Arrived',
        notPackage: 'No Package',
        add:'Add',
        package:'Package',
        addOrder:'Add Tracking No',
        houseName:'Warehouse',
        houseNameLabel:'Select Warehouse',
        courierNo:'Tracking No',
        courierNoLabel:'Input Tracking No',
        transType:'Ship By',
        ship:'SEA',
        plane:'AIR'
    },
    order:{
        all:'All',
        inHouse:'In Warehouse',
        transit:'In Transit',
        arrived:'Arrived',
        notOrder:'No Order'
    },
    me:{
        myAssets:'My Assets',
        myService:'My Service',
        points:'Points',
        balance:'Balance',
        coupons:'Coupons',
        settings:'Settings',
    },
    settings:{
        avatar:'Avatar',
        nickName:'Nick Name',
        changeName:'Change Nick Name',
        changeNameLabel:'please input nick name',
        changePassword:'Change Password',
        logout:'Logout',
        logoutAccount:'Logout of current account',
        title:'Settings',
        oldPwd:'old pwd',
        oldPwdLabel:'old password',
        newPwd:'new pwd',
        newPwdLabel:'new password',
        confirmPwd:'confirm pwd',
        confirmPwdLabel:'new password again'
    },
    detail:{
        title:'Order Details',
        orderNo:'Tracking No',
        total:'Total',
        createTime: 'Created Time',
        customCode:'Custom Code',
        name:'Name',
        mobile:'Mobile',
        from:'From',
        to:'To',
        container:'Container',
        etd:'ETD',
        eta:'ETA',
        specification:'Specification',
        goods:'Goods',
        dimension:'Dimension',
        weight:'Weight',
        ctn:'Ctn',
        package:'Package',
        logisticsStatus:'Logistics Status',
        remark:'Remarks'
    }
}