<template>
<div>
  <div class="col-4 grid tabbar">
    <div @click.stop="handelClick(item)"
        class="overflow flex flex-direction justify-center align-center"
         v-for="(item,index) in list" :key="index">
      <img class="icon-img" :src="tabCur === item.label ? item.selectImg : item.img" />
      <span class="text-sm" :style="{color:tabCur === item.label ? activeColor : 'inherit'}">{{$t('message.'+item.label)}}</span>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "cu-tabbar",
  computed:{
    tabCur(){
      return this.$store.getters['basic/get_tabCur']
    }
  },
  props:{
    list:{
      type:Object,
      default:[]
    },
    activeColor:{
      type:String,
      default: '#1296db'
    },
  },
  methods:{
    handelClick(item){
      this.$emit('change',item)
    }
  }
}
</script>

<style lang="scss" scoped>
.tabbar{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  background-color: #ffffff;
}
.icon-img{
  width: 20px;
  height: 20px;
  margin-top: 5px;
}

</style>