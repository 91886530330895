<template>
<div class="page-100">
  <div class="page-body">
    <router-view/>
  </div>
  <cu-tabbar :list="navList" @change="tabChange"></cu-tabbar>
</div>
</template>

<script>
import cuTabbar from "@/components/cu-tabbar/cu-tabbar";
export default {
  name: "layout",
  components:{
    cuTabbar,
  },
  data() {
    return {
      active:'home',
      navList:[
        {label:'home',img:'/tabbar/home.png',selectImg:'/tabbar/home_blue_fill.png',url:'/home'},
        {label:'package',img:'/tabbar/goods.png',selectImg: '/tabbar/goods_blue_fill.png',url:'/package'},
        {label:'order',img:'/tabbar/plane.png',selectImg: '/tabbar/plane_blue_fill.png',url:'/order'},
        {label:'me',img:'/tabbar/me.png',selectImg: '/tabbar/me_blue_fill.png',url:'/me'},
      ]
    }
  },
  methods:{
    tabChange(val){
      let row = this.navList.find(item => item.label === val.label);
      this.$router.push({path:row.url});
      this.$store.dispatch('basic/up_tabCur',row.label);
    }
  }
}
</script>

<style scoped>

</style>