import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/views/layout/layout'
import store from '@/store/index'

const routes = [
  {
    path: '/',
    name: 'layout',
    redirect:'/home',
    component: Layout,
    children:[
      {
        path:'/home',
        name:'home',
        component: () => import('@/views/home/home')
      },
      {
        path:'/package',
        name:'package',
        component: () => import('@/views/package/package')
      },
      {
        path:'/order',
        name:'order',
        component: () => import('@/views/order/order')
      },
      {
        path:'/me',
        name: 'me',
        component: () => import('@/views/me/me')
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '@/views/me/login')
  },
  {
    path:'/address',
    name: 'address-page',
    component: () => import('@/views/me/address')
  },
  {
    path:'/settings',
    name:'settings',
    component: () =>  import('@/views/settings/settings')
  },
  {
    path:'/order-detail',
    name:'order-detail',
    component: () => import('@/views/order/detail')
  },
  {
    path: '/add-order',
    name:'add-order',
    component: () => import('@/views/package/add-package')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to,from,next) => {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;
  if (!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent)) {
    // 是手机访问，跳转到A域名
    window.location.href = 'https://vip.dsc56.com';
  }else {
    //在页面加载时读取sessionStorage里的状态信息
    if (window.sessionStorage.getItem("store")) {
      store.replaceState(
          Object.assign({}, JSON.parse(sessionStorage.getItem("store")))
      );
      window.sessionStorage.removeItem("store");
    }

    //检测是否登陆
    let user = store.getters['basic/get_user'];
    if (!user && to.path !== '/login'){
      next({path:'/login'});
    }else {
      if (to.path === '/login') {
        store.dispatch('basic/up_user', null).then(r => r);
        store.dispatch('basic/up_tabCur','home').then(r => r);
      }
      next();
    }
    document.title  = to.meta.title ?? to.name;
  }

})

export default router
